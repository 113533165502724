import * as React from "react";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import Lottie from "react-lottie";

import { IToolData, IWellbeingQuiz, WizardFormContext } from "../../App";
import { IUseWizard } from "../../hooks/useWizard";

import { keys, calcResult, getWeaknesses, getStrengths } from "../../results";
import { getAmplitudeInstance } from "../../analytics";
import getToolComponentForName from "../ToolCard/toolList";
import ToolCard from "../ToolCard";
import stepsData from "../../stepData";

const ResultStep = () => {
  const { width, height } = useWindowSize();
  const { data } =
    React.useContext<IUseWizard<IWellbeingQuiz>>(WizardFormContext);
  const [showTools, setShowTools] = React.useState<boolean>(false);

  let totalNum = 0;
  keys.forEach((key) => (totalNum += data[key] ?? 0));

  const result = calcResult(totalNum);
  const clearheadUrl = React.useMemo(() => {
    let clearheadDomain = "myclearhead.com" 
    if (typeof window != "undefined") {
      if(window.location.host.startsWith('quiz.clearhead.org.nz')){
        clearheadDomain = "clearhead.org.nz"
      }
    }

    if (data?.cohort && data.cohort.length > 0) {
      return `https://${data?.cohort}.${clearheadDomain}`;
    } else {
      return `https://www.${clearheadDomain}`;
    }
  }, [data?.cohort]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    getAmplitudeInstance().logEvent("view-results", {
      value: totalNum,
    });

    fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/tenant?clientCode=${data?.cohort}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Recieved error from API");
        }
        return response.json();
      })
      .then((data) => {
        if (data.hasPremiumFeatures) {
          setShowTools(true);
        }
      })
      .catch((error) => {
        console.error("ResultStep: ", error);
      });
    if (data?.cohort === "firststeps") {
      fetch(`${process.env.REACT_APP_API_URL}/api/v1/quiz`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
        }),
      })
        .then((res) => {
          if (res.ok) {
            getAmplitudeInstance().logEvent("saved-quiz", {
              ...data,
            });
          } else {
            getAmplitudeInstance().logEvent("failed-save-quiz", {
              ...data,
            });
          }
        })
        .catch((error) => {
          console.error("ResultStep: ", error);
          getAmplitudeInstance().logEvent("failed-save-quiz", {
            ...data,
          });
        });
    }
  }, [data?.cohort]);

  const allEqual = stepsData.every(
    (step) => data[step.id] === data["mentallyResilient"]
  );

  return (
    <div className="px-4">
      {totalNum > 11 ? (
        <Confetti
          numberOfPieces={25}
          tweenDuration={20000}
          width={width}
          height={height}
        />
      ) : null}
      <div className="mb-10">
        <div className="mb-3">
          <Lottie
            options={{
              animationData: result?.image,
              loop: true,
              autoplay: true,
            }}
            height={200}
            width={200}
          />
        </div>
        <h1 className="text-center text-3xl text-darkBlue mb-3">
          {result?.title}
        </h1>

        {data.cohort === "firststeps" ? (
          <p className="text-center mb-4 bg-yellow-100 rounded-md">
            If you need support now: Free call or text{" "}
            <a href="tel:1737" className="text-blue">
              1737
            </a>
          </p>
        ) : null}

        {data.cohort === "firststeps" ? (
          <>
            <div className="mb-4 text-center">
              First steps has built an expansive resource library for business
              owners.
            </div>
            <>
              <button
                type="submit"
                className={`transition-all block w-full sm:inline-flex sm:justify-center sm:items-center px-6 py-3 border border-transparent rounded-full text-white bg-blue hover:bg-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light mb-3`}
                onClick={() => {
                  window.open("https://firststeps.nz/resources/", "_blank");
                }}
              >
                View the First Steps Resource Library
              </button>
            </>
          </>
        ) : null}

        <div className="text-center">
          <div className="mb-10">{result?.body()}</div>
        </div>

        {data.toolData?.length && (
          <>
            <hr className="mt-3 mb-10" />
            <div className="text-center mb-8">
              {showTools && (
                <h3 className="mb-3 text-darkBlue text-xl">
                  What tools are right for me?
                </h3>
              )}
              {!allEqual && (
                <div className="mb-3">
                  <p>
                    You scored <span className="text-blue">lowest</span> on{" "}
                    <span className="font-bold text-blue">
                      {getWeaknesses(data)}
                    </span>
                    .
                  </p>
                  <p>
                    You scored <span className="text-green">highest</span> on{" "}
                    <span className="font-bold text-green">
                      {getStrengths(data)}
                    </span>
                    .
                  </p>
                </div>
              )}

              {showTools && (
                <>
                  <p className="mb-3">
                    These scores, along with what you've said, tells us what
                    tools are most suited to you.
                  </p>
                  <p className="mb-4">Here are our top three picks:</p>
                  <div className="text-left">
                    {data.toolData.map((toolData: IToolData) => {
                      const tool = getToolComponentForName(toolData.title);
                      if (tool) {
                        return (
                          <ToolCard
                            toolData={toolData}
                            tool={tool}
                            key={toolData.title}
                            baseUrl={clearheadUrl}
                            referrer={data.cohort}
                          />
                        );
                      }

                      return <></>;
                    })}
                  </div>
                </>
              )}
            </div>
          </>
        )}
        {data.cohort !== "firststeps" ? (
          <>
            <div className="text-center">
              <hr className="mt-10 mb-10" />
              <h3 className="text-center text-xl text-darkBlue mb-3">
                {showTools
                  ? "Not in the mood to use any tools?"
                  : "Build your wellbeing resilience"}
              </h3>
              <div className="mb-8">
                {result?.improve(clearheadUrl, 3, data.cohort)}
              </div>
            </div>

            <button
              type="submit"
              className={`transition-all block w-full sm:inline-flex sm:justify-center sm:items-center px-6 py-3 border border-transparent rounded-full text-white bg-blue hover:bg-blue-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-light`}
              onClick={() => result?.buttonUrl(clearheadUrl, data.cohort)}
            >
              {result?.buttonText}
            </button>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ResultStep;
